import dayjs, { Dayjs } from "dayjs";
import { forwardRef, useLayoutEffect, useRef, useState } from "react";

import Calendar, { ValidRange } from "../Calendar";
import Hovercard from "../Hovercard";
import Input, { InputProps } from "../Input";
import SvgIcon from "../SvgIcon";

interface DatePickerProps {
  autofocus?: boolean;
  disabled?: boolean;
  id?: string;
  invalid?: boolean;
  label?: string;
  name?: string;
  /** Called with ISO String format */
  onChange: (date: string | null) => void;
  placeholder?: string;
  size?: InputProps["size"];
  /** A tuple denoting the limits of the pickable range. If an entry is null, then the range has no limit */
  validRange?: ValidRange;
  /** This can be a string of anything that dayjs accepts */
  value?: string;
}

/**
 * This component has both a Calendar popup from which you can navigate to and select a specific
 * date, as well as an input field in which you can type in a date directly. We use dayjs liberally
 * in here, but the `value` passed in and the value sent back out in the `onChange` handler is an
 * ISO date string.
 */
const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (
    {
      autofocus,
      disabled,
      name,
      invalid,
      onChange,
      size,
      validRange = [null, null],
      label,
      id,
      value,
      placeholder = "Select a date",
    },
    ref,
  ) => {
    const [searchText, setSearchText] = useState("");
    const [open, setOpen] = useState(false);
    const isDateValid = (current: Dayjs) =>
      current.isValid() &&
      (!validRange[0] || !current.isBefore(validRange[0], "day")) &&
      (!validRange[1] || !current.isAfter(validRange[1], "day"));

    const containerRef = useRef<HTMLInputElement>();
    const hovercardRef = useRef<HTMLInputElement>();
    const _inputRef = useRef<HTMLInputElement>();
    const inputRef = ref || _inputRef;

    useLayoutEffect(() => {
      // update search text to match if we get a new value passed in
      if (value && value !== searchText && isDateValid(dayjs(value))) {
        setSearchText(formatTextForDisplay(value));
      }
    }, [value]);

    return (
      <div ref={containerRef} className="DatePicker">
        <SvgIcon name="calendar" />
        <Input
          autofocus={autofocus}
          ref={inputRef}
          autocomplete="off"
          disabled={disabled}
          label={label}
          id={id}
          invalid={invalid}
          name={name}
          onBlur={() => {
            if (
              searchText &&
              isDateValid(dayjs(searchText)) &&
              !dayjs(searchText).isSame(dayjs(value))
            ) {
              let formattedDisplay = formatTextForDisplay(searchText);

              setSearchText(formattedDisplay);
              onChange(dayjs(searchText).toISOString());
            } else if (!searchText) {
              onChange(null);
            } else {
              // revert text to whatever the last is
              setSearchText(formatTextForDisplay(value));
            }

            window.setTimeout(() => {
              if (!hovercardRef.current?.contains(document.activeElement)) {
                setOpen(false);
              }
            }, 0);
          }}
          onFocus={() => setOpen(true)}
          onChange={setSearchText}
          placeholder={placeholder}
          size={size}
          value={searchText}
        />
        <Hovercard ref={hovercardRef} anchorEl={containerRef.current} visible={open}>
          <Calendar
            flavor="date"
            selectedRange={
              // if the search text is a valid date, mirror it in the calendar
              isDateValid(dayjs(searchText)) ? [dayjs(searchText)]
                // clear the entry in the calendar if all input text has been removed
              : !searchText || !dayjs(value).isValid() ?
                []
                // if search text is not valid but the last saved value is, show that
              : [dayjs(value)]
            }
            onSelectRange={([day]) => {
              onChange(day.toISOString());
              setOpen(false);
            }}
            validRange={validRange}
          />
        </Hovercard>
      </div>
    );
  },
);

export default DatePicker;

function formatTextForDisplay(text: string) {
  return dayjs(text).format("MMM DD, YYYY");
}
